export default class AppApiConfig {
  static API_ENDPOINT = "https://demo-api.opsacuity.com/"; // DEMO
  // static API_ENDPOINT = "https://opsacuity-dev-be.archesoftronix.in/"; // DEV
  // static API_ENDPOINT = "https://opsacuity-qa-be.archesoftronix.in/"; // QA
  // static API_ENDPOINT = "https://opsacuity-uat-be.archesoftronix.in/"; // UAT
  // static API_ENDPOINT = "http://192.168.29.54:40800/"; // LOCAL
  // static API_ENDPOINT = "https://m62wvq86-7144.inc1.devtunnels.ms/";
  // static API_ENDPOINT = "https://localhost:7144/";
  // static API_ENDPOINT = "https://dotnetarche.bsite.net/"

  static API_ADMIN_ENDPOINT = "api";

  /* AUTH API */
  static API_USER_AUTH_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Auth";
  static API_USER_LOGIN_URL = AppApiConfig.API_USER_AUTH_URL + "/Login";
  static API_USER_SEND_OTP_FOR_EMAIL_URL =
    AppApiConfig.API_USER_AUTH_URL + "/SendOTPOnEmailId";
  static API_USER_VERIFY_OTP_URL =
    AppApiConfig.API_USER_AUTH_URL + "/VerifyOTP";
  static API_USER_RESET_PASSWORD_URL =
    AppApiConfig.API_USER_AUTH_URL + "/ResetPassword";
  /* AUTH API */

  /* COMMON APIS */
  static API_COMMON_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Common";
  static API_ROLE_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetAllRoleList";
  static API_SUFFIX_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetAllSuffixList";
  static API_BASE_WAGE_TYPE_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetAllBaseWageTypeList";
  static API_TYPE_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetAllTypeList";
  static API_STATUS_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetAllStatusList";
  static API_EDUCATION_LEVEL_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetAllEducationLevelList";
  static API_EDUCATION_DEGREE_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetAllEducationDegreeList";
  static API_REPORTING_TO_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetAllReportingList";
  static API_OPERATION_STATUS_LIST_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetOperationStatusList";
  static API_QUOTE_TYPE_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetQuoteTypeList";
  static API_PROBABILITY_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetProbabilityList";
  static API_LEAD_SOURCE_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetLeadSourceList";
  static API_CUSTOMER_TYPE_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetCustomerTypeList";
  static API_STAGE_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetStageList";
  static API_QUOTE_STATUS_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetQuoteStatusList";
  static API_ACTIVITY_TYPE_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetActivityTypeList";
  static API_UNIT_OF_MEASURE_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetUnitOfMeasureList";
  static API_BUSUNESS_TYPE_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetCustomerTypeList";
  static API_SOURCE_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetLeadSourceList";
  static API_CAMPAIGN_STATUS_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetCampaignStatusList";
  static API_SALES_LEAD_SOURCE_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetLeadSourceList";
  static API_LEAD_STATUS_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetLeadStatusList";
  static API_PROJECT_SCHEDULE_STATUS_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetProjectScheduleStatusList";
  /* COMMON APIS */

  /* DEPARTMENT MODULE */
  static API_DEPARTMENT_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Department";
  static API_DEPARTMENT_MODULE_LIST_URL =
    AppApiConfig.API_DEPARTMENT_URL + "/GetAllDepartmentDetailList";
  static API_SAVE_DEPARTMENT_MODULE_URL =
    AppApiConfig.API_DEPARTMENT_URL + "/AddEditDepartment";
  static API_DEPARTMENT_SELECT_LIST_URL =
    AppApiConfig.API_DEPARTMENT_URL + "/GetDepartmentList";
  static API_LOAD_DEPARTMENT_DETAIL_URL =
    AppApiConfig.API_DEPARTMENT_URL + "/GetDepartmentById";
  static API_CHANGE_DEPARTMENT_MODULE_STATUS_URL =
    AppApiConfig.API_DEPARTMENT_URL + "/ActiveDeactiveDepartment";
  /* DEPARTMENT MODULE */

  /* REGION MODULE */
  static API_REGION_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Region";
  static API_REGION_MODULE_LIST_URL =
    AppApiConfig.API_REGION_URL + "/GetRegionDetailList";
  static API_LOAD_REGION_DETAIL_URL =
    AppApiConfig.API_REGION_URL + "/GetRegionById";
  static API_SAVE_REGION_MODULE_URL =
    AppApiConfig.API_REGION_URL + "/AddEditRegion";
  static API_CHANGE_REGION_MODULE_STATUS_URL =
    AppApiConfig.API_REGION_URL + "/ActiveDeactiveRegion";
  static API_REGION_SELECT_LIST_URL =
    AppApiConfig.API_REGION_URL + "/GetRegionList";
  static API_COUNTRY_SELECT_LIST_URL =
    AppApiConfig.API_REGION_URL + "/GetAllCountryList";
  static API_STATE_SELECT_LIST_URL =
    AppApiConfig.API_REGION_URL + "/GetAllStateListByCountryId";
  static API_CITY_SELECT_LIST_URL =
    AppApiConfig.API_REGION_URL + "/GetAllCityListByStateId";
  /* REGION MODULE */

  /* PROJECT SUMMARY MODULE */
  static API_PROJECT_SUMMARY_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Project";
  static API_CUSTOMER_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Customer";
  static API_PROJECT_SUMMARY_LIST_URL =
    AppApiConfig.API_PROJECT_SUMMARY_URL + "/GetAllProjectList";
  static API_SAVE_PROJECT_SUMMARY_MODULE_URL =
    AppApiConfig.API_PROJECT_SUMMARY_URL + "/AddEditProject";
  static API_GENERATE_PROJECT_ID_PROJECT_SUMMARY_MODULE_URL =
    AppApiConfig.API_PROJECT_SUMMARY_URL + "/GenerateProjectId";
  static API_CUSTOMER_LIST_PROJECT_SUMMARY_MODULE_URL =
    AppApiConfig.API_CUSTOMER_URL + "/GetCustomerList";
  static API_LOAD_PROJECT_SUMMARY_DETAIL_URL =
    AppApiConfig.API_PROJECT_SUMMARY_URL + "/GetProjectById";
  static API_PROJECT_LIST_URL =
    AppApiConfig.API_PROJECT_SUMMARY_URL + "/GetProjectList";
  static API_ACTIVE_INACTIVE_PROJECT_SUMMARY_URL =
    AppApiConfig.API_PROJECT_SUMMARY_URL + "/EditProjectActivation";
  static API_UPDATE_PROJECT_SUMMARY_STATUS_URL =
    AppApiConfig.API_PROJECT_SUMMARY_URL + "/UpdateProjectStatus";
  static API_PROJECT_SUMMARY_MODULE_LIST_BY_STATUS_URL =
    AppApiConfig.API_PROJECT_SUMMARY_URL + "/GetProjectListByStatus";
  /* PROJECT SUMMARY MODULE */

  /* EMPLOYEE MANAGEMENT MODULE */
  static API_EMPLOYEE_MANAGEMENT_URL =
    AppApiConfig.API_ADMIN_ENDPOINT + "/User";
  static API_EMPLOYEE_MANAGEMENT_MODULE_LIST_URL =
    AppApiConfig.API_EMPLOYEE_MANAGEMENT_URL + "/GetAllUserList";
  static API_SAVE_EMPLOYEE_MANAGEMENT_MODULE_URL =
    AppApiConfig.API_EMPLOYEE_MANAGEMENT_URL + "/AddEditAllUser";
  static API_GENERATE_EMPLOYEE_CODE_EMPLOYEE_MANAGEMENT_MODULE_URL =
    AppApiConfig.API_EMPLOYEE_MANAGEMENT_URL + "/GenerateEmployeeCode";
  static API_LOAD_EMPLOYEE_MANAGEMENT_PHASE1_DETAIL_URL =
    AppApiConfig.API_EMPLOYEE_MANAGEMENT_URL + "/GetAllUserListByIdPhase1";
  static API_LOAD_EMPLOYEE_MANAGEMENT_PHASE2_DETAIL_URL =
    AppApiConfig.API_EMPLOYEE_MANAGEMENT_URL + "/GetAllUserListByIdPhase2";
  static API_SAVE_EMPLOYEE_MANAGEMENT_PHASE1_DETAIL_URL =
    AppApiConfig.API_EMPLOYEE_MANAGEMENT_URL + "/AddEditUserPhase1";
  static API_SAVE_EMPLOYEE_MANAGEMENT_PHASE2_DETAIL_URL =
    AppApiConfig.API_EMPLOYEE_MANAGEMENT_URL + "/AddEditUserPhase2";
  static API_CHANGE_EMPLOYEE_MANAGEMENT_STATUS_URL =
    AppApiConfig.API_EMPLOYEE_MANAGEMENT_URL + "/UpdateUserStatus";
  static API_USER_FULL_NAME_SELECT_LIST_URL =
    AppApiConfig.API_EMPLOYEE_MANAGEMENT_URL + "/GetAllUserFullNameList";
  /* EMPLOYEE MANAGEMENT MODULE */

  /* DRIVER CERTIFICATION MODULE */
  static API_DRIVER_CERTIFICATION_URL =
    AppApiConfig.API_ADMIN_ENDPOINT + "/DriverCertification";
  static API_DRIVER_CERTIFICATION_MODULE_LIST_URL =
    AppApiConfig.API_DRIVER_CERTIFICATION_URL +
    "/GetAllDriverCertificationListByUserId";
  static API_SAVE_DRIVER_CERTIFICATION_MODULE_URL =
    AppApiConfig.API_DRIVER_CERTIFICATION_URL + "/AddEditDriverCertification";
  static API_CHANGE_DRIVER_CERTIFICATION_STATUS_URL =
    AppApiConfig.API_DRIVER_CERTIFICATION_URL +
    "/UpdateDriverCertificationStatus";
  /* DRIVER CERTIFICATION MODULE */

  /* DOCUMENT MANAGEMENT MODULE */
  static API_DOCUMENT_MANAGEMENT_URL =
    AppApiConfig.API_ADMIN_ENDPOINT + "/Document";
  static API_EMPLOYEE_DOCUMENT_MANAGEMENT_MODULE_LIST_URL =
    AppApiConfig.API_DOCUMENT_MANAGEMENT_URL + "/GetAllUserDocumentList";
  static API_EMPLOYEE_DOCUMENT_MANAGEMENT_MODULE_DETAIL_URL =
    AppApiConfig.API_DOCUMENT_MANAGEMENT_URL + "/GetUserDocumentById";
  static API_COMPANY_DOCUMENT_MANAGEMENT_MODULE_DETAIL_URL =
    AppApiConfig.API_DOCUMENT_MANAGEMENT_URL + "/GetCompanyDocumentById";
  static API_COMPANY_DOCUMENT_MANAGEMENT_MODULE_LIST_URL =
    AppApiConfig.API_DOCUMENT_MANAGEMENT_URL + "/GetAllCompanyDocumentList";
  static API_DOCUMENT_TYPE_SELECT_LIST_URL =
    AppApiConfig.API_DOCUMENT_MANAGEMENT_URL + "/GetAllDocumentTypeList";
  static API_SAVE_EMPLOYEE_DOCUMENT_MANAGEMENT_URL =
    AppApiConfig.API_DOCUMENT_MANAGEMENT_URL + "/AddEditUserDocument";
  static API_SAVE_COMPANY_DOCUMENT_MANAGEMENT_URL =
    AppApiConfig.API_DOCUMENT_MANAGEMENT_URL + "/AddEditCompanyDocument";
  /* DOCUMENT MANAGEMENT MODULE */

  /* ATTENDANCE MANAGEMENT MODULE */
  static API_ATTENDANCE_MANAGEMENT_URL =
    AppApiConfig.API_ADMIN_ENDPOINT + "/Attendance";
  static API_ATTENDANCE_MANAGEMENT_LIST_URL =
    AppApiConfig.API_ATTENDANCE_MANAGEMENT_URL + "/GetAllAttendanceList";
  static API_IMPORT_ATTENDANCE_MANAGEMENT_SHEET_URL =
    AppApiConfig.API_ATTENDANCE_MANAGEMENT_URL + "/ImportAttendanceSheet";
  static API_ATTENDANCE_MANAGEMENT_MARK_USER_ABSENT_URL =
    AppApiConfig.API_ATTENDANCE_MANAGEMENT_URL + "/MarkUserAbsent";
  /* ATTENDANCE MANAGEMENT MODULE */

  /*LEAVE MANAGEMENT MODULE */
  static API_LEAVE_MANAGEMENT_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Leave";
  static API_LEAVE_MANAGEMENT_MODULE_LIST_URL =
    AppApiConfig.API_LEAVE_MANAGEMENT_URL + "/GetAllLeaveList";
  static API_LOAD_LEAVE_MANAGEMENT_MODULE_DETAILS_URL =
    AppApiConfig.API_LEAVE_MANAGEMENT_URL + "/GetAllLeaveListById";
  static API_SAVE_LEAVE_MANAGEMENT_MODULE_URL =
    AppApiConfig.API_LEAVE_MANAGEMENT_URL + "/AddEditLeave";
  static API_UPDATE_LEAVE_MANAGEMENT_MODULE_STATUS_URL =
    AppApiConfig.API_LEAVE_MANAGEMENT_URL + "/UpdateLeaveStatus";
  static API_DELETE_LEAVE_MANAGEMENT_MODULE_URL =
    AppApiConfig.API_LEAVE_MANAGEMENT_URL + "/DeleteLeave";
  /*LEAVE MANAGEMENT MODULE */

  /* WORKFLOW MODULE */
  //PROJECT SCHEDULING
  static API_PROJECT_SCEDULING_URL =
    AppApiConfig.API_ADMIN_ENDPOINT + "/ProjectSchedule";
  static API_PROJECT_SCHEDULING_LIST_URL =
    AppApiConfig.API_PROJECT_SCEDULING_URL + "/GetAllProjectScheduleDetailList";
  static API_ADD_EDIT_PROJECT_SCHEDULING_URL =
    AppApiConfig.API_PROJECT_SCEDULING_URL + "/AddEditProjectSchedule";
  static API_EDIT_PROJECT_SCHEDULING_STATUS_URL =
    AppApiConfig.API_PROJECT_SCEDULING_URL + "/EditProjectScheduleStatus";
  static API_GET_PROJECT_SCHEDULING_DETAIL_URL =
    AppApiConfig.API_PROJECT_SCEDULING_URL + "/GetProjectScheduleById";
  static API_ACTIVE_INACTIVE_PROJECT_SCHEDULING_URL =
    AppApiConfig.API_PROJECT_SCEDULING_URL + "/ActiveDeactiveProjectSchedule";
  static API_EMPLOYEE_SELECT_LIST =
    AppApiConfig.API_EMPLOYEE_MANAGEMENT_URL + "/GetProjectScheduleUserList";
  // FIELD DATA
  static API_FIELD_DATA_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/FieldData";
  static API_FIELD_DATA_DETAIL_LIST_URL =
    AppApiConfig.API_FIELD_DATA_URL + "/GetAllFieldDataDetailList";
  static API_PROJECT_DETAILS_BY_ID_URL =
    AppApiConfig.API_PROJECT_SUMMARY_URL + "/GetProjectById";
  static API_ACTIVITY_STATUS_SELECT_LIST_URL =
    AppApiConfig.API_COMMON_URL + "/GetFieldDataStatusList";
  static API_FEILD_DATA_LIST_URL =
    AppApiConfig.API_FIELD_DATA_URL + "/GetFieldDataList";
  static API_FIELD_DATA_LIST_URL =
    AppApiConfig.API_FIELD_DATA_URL + "/GetAllFieldDataDetailList";
  static API_SAVE_FIELD_DATA_MODULE_URL =
    AppApiConfig.API_FIELD_DATA_URL + "/AddEditFieldData";
  static API_LOAD_FIELD_DATA_DETAIL_URL =
    AppApiConfig.API_FIELD_DATA_URL + "/GetFieldDataById";
  static API_CHANGE_FIELD_DATA_STATUS_URL =
    AppApiConfig.API_FIELD_DATA_URL + "/ActiveDeactiveFieldData";
  static API_LOAD_FIELD_DATA_DETAIL_BY_PROJECT_ID_URL =
    AppApiConfig.API_FIELD_DATA_URL + "/GetFieldDataByProjectId";
  static API_UPDATE_FIELD_DATA_STATUS_URL =
    AppApiConfig.API_FIELD_DATA_URL + "/UpdateFieldDataStatus";
  /* WORKFLOW MODULE*/

  /* CUSTOMER MANAGEMENT MODULE*/
  static API_CUSTOMER_MANAGEMENT_URL =
    AppApiConfig.API_ADMIN_ENDPOINT + "/customer";
  static API_CUSTOMER_MANAGEMENT_LIST_URL =
    AppApiConfig.API_CUSTOMER_MANAGEMENT_URL + "/GetAllCustomerDetailList";
  static API_ADD_EDIT_CUSTOMER_URL =
    AppApiConfig.API_CUSTOMER_MANAGEMENT_URL + "/AddEditCustomer";
  static API_GENERATE_CUSTOMER_ID_URL =
    AppApiConfig.API_CUSTOMER_MANAGEMENT_URL + "/GetCustomerById";
  static API_GET_CUSTOMER_ID_URL =
    AppApiConfig.API_CUSTOMER_MANAGEMENT_URL + "/GenerateCustomerId";
  static API_CUSTOMER_SELECT_LIST_URL =
    AppApiConfig.API_CUSTOMER_MANAGEMENT_URL + "/GetCustomerList";
  static API_UPDATE_CUSTOMER_STATUS_URL =
    AppApiConfig.API_CUSTOMER_MANAGEMENT_URL + "/ActiveDeactiveCustomer";
  /* CUSTOMER MANAGEMENT MODULE*/

  /* QUOTES MODULE */
  static API_QUOTES_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Quote";
  static API_QUOTES_MODULE_LIST_URL =
    AppApiConfig.API_QUOTES_URL + "/GetAllQuoteDetailList";
  static API_SAVE_QUOTE_MODULE_URL =
    AppApiConfig.API_QUOTES_URL + "/AddEditQuote";
  static API_LOAD_QUOTE_DETAIL_URL =
    AppApiConfig.API_QUOTES_URL + "/GetQuoteById";
  /* QUOTES MODULE */

  /* LEAD MODULE */
  static API_LEAD_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Lead";
  static API_TITLE_SELECT_LIST_URL =
    AppApiConfig.API_LEAD_URL + "/GetTitleByCustomerId";
  static API_LEAD_SELECT_LIST_URL = AppApiConfig.API_LEAD_URL + "/GetLeadList";
  static API_LEAD_BY_CAMPAIGN_ID_SELECT_LIST_URL =
    AppApiConfig.API_LEAD_URL + "/GetLeadByCampaignId";
  static API_LEAD_DETAIL_LIST_URL =
    AppApiConfig.API_LEAD_URL + "/GetAllLeadDetailList";
  static API_ADD_EDIT_LEAD_DETAIL_URL =
    AppApiConfig.API_LEAD_URL + "/AddEditLead";
  static API_LEAD_DETAIL_URL = AppApiConfig.API_LEAD_URL + "/GetLeadById";
  static API_LEADPOOL_IMPORT_DETAIL_URL =
    AppApiConfig.API_LEAD_URL + "/ImportLeadsExcel";
  static API_UPDATE_LEAD_STATUS_URL =
    AppApiConfig.API_LEAD_URL + "/UpdateLeadStatus";
  static API_UPDATE_LEAD_STAGE_URL =
    AppApiConfig.API_LEAD_URL + "/UpdateLeadStage";
  /* LEAD MODULE */

  /*FLEET MANAGEMENT MODULE */

  /*FLEET MANAGEMENT MODULE */

  /* CAMPAIGNS MODULE */
  static API_CAMPAIGNS_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Campaign";
  static API_CAMPAIGNS_MODULE_LIST_URL =
    AppApiConfig.API_CAMPAIGNS_URL + "/GetAllCampaignDetailList";
  static API_SAVE_CAMPAIGNS_DETAIL_URL =
    AppApiConfig.API_CAMPAIGNS_URL + "/AddEditCampaign";
  static API_CAMPAIGNS_LIST_URL =
    AppApiConfig.API_CAMPAIGNS_URL + "/GetCampaignList";
  static API_SAVE_ASSIGN_AGENT_DETAIL_URL =
    AppApiConfig.API_CAMPAIGNS_URL + "/AddEditCampaign";
  static API_SAVE_MENUAL_ASSIGN_DETAIL_URL =
    AppApiConfig.API_CAMPAIGNS_URL + "/AddEditCampaign";
  static API_UPDATE_CAMPAIGN_STATUS_URL =
    AppApiConfig.API_CAMPAIGNS_URL + "/UpdateCampaignStatus";
  static API_LOAD_CAMPAIGN_DETAIL_URL =
    AppApiConfig.API_CAMPAIGNS_URL + "/GetCampaignById";
  static API_SAVE_MANUAL_ASSIGN_AGENT_URL =
    AppApiConfig.API_CAMPAIGNS_URL + "/ManualAssignAgent";
  static API_SAVE_ASSIGN_AGENT_URL =
    AppApiConfig.API_CAMPAIGNS_URL + "/AssignAgents";
  static API_LOAD_SEARCH_LEAD_COUNT_DETAIL_URL =
    AppApiConfig.API_CAMPAIGNS_URL + "/SearchLeadCount";
  /* CAMPAIGNS MODULE */

  /*  AGENT MODULE */
  static API_AGENT_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Agent";
  static API_AGENT_MODULE_LIST_URL =
    AppApiConfig.API_AGENT_URL + "/GetAllAgentsDetailList";
  static API_LOAD_CAMPAIGN_DETAIL_BY_AGENT_ID_LIST_URL =
    AppApiConfig.API_AGENT_URL + "/GetCampaignDetailByAgentId";
  static API_SAVE_CAMPAIGN_NOTE_DETAIL_URL =
    AppApiConfig.API_AGENT_URL + "/AddEditCampaignNote";
  static API_CHANGE_CAMPAIGN_AGENT_STATUS_URL =
    AppApiConfig.API_AGENT_URL + "/UpdateCampaignAgentStatus";
  static API_AGENT_NAME_LIST_URL =
    AppApiConfig.API_AGENT_URL + "/GetAgentFullNameList";
  /*  AGENT MODULE */

  /* CRM DASHBOARD */
  static API_CRM_DASHBOARD_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Dashboard";
  static API_CRM_DASHBOARD_MODULE_LIST_URL =
    AppApiConfig.API_CRM_DASHBOARD_URL + "/GetAllCRMDashboardList";
  /* CRM DASHBOARD */

  /* SALES REP */
  static API_SALES_REP_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/SalesRep";
  static API_SALES_REP_MODULE_LIST_URL =
    AppApiConfig.API_SALES_REP_URL + "/GetAllSalesRepList";
  static API_LOAD_PROJECT_DETAIL_BY_SALES_REP_ID_URL =
    AppApiConfig.API_SALES_REP_URL + "/GetAllProjectListBySalesRepId";
  static API_LOAD_CUSTOMER_DETAIL_BY_SALES_REP_ID_URL =
    AppApiConfig.API_SALES_REP_URL + "/GetAllCustomerListBySalesRepId";
  static API_LOAD_LEAD_DETAIL_BY_SALES_REP_ID_URL =
    AppApiConfig.API_SALES_REP_URL + "/GetAllLeadListBySalesRepId";
  /* SALES REP */

  /* VENDORS MODULE*/
  static API_VENDORS_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Vendor";
  static API_VENDORS_MODULE_LIST_URL =
    AppApiConfig.API_VENDORS_URL + "/GetAllVendorDetailList";
  static API_SAVE_VENDORS_DETAIL_URL =
    AppApiConfig.API_VENDORS_URL + "/AddEditVendor";
  static API_GENERATE_VENDOR_ID_URL =
    AppApiConfig.API_VENDORS_URL + "/GenerateVendorId";
  static API_LOAD_VENDOR_DETAIL_URL =
    AppApiConfig.API_VENDORS_URL + "/GetVendorById";
  static API_CHANGE_VENDOR_MODULE_STATUS_URL =
    AppApiConfig.API_VENDORS_URL + "/ActiveDeactiveVendor";
  /* VENDORS MODULE*/

  /* PARTNERS MODULE*/
  static API_PARTNERS_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Partner";
  static API_PARTNERS_MODULE_LIST_URL =
    AppApiConfig.API_PARTNERS_URL + "/GetAllPartnerDetailList";
  static API_SAVE_PARTNERS_DETAIL_URL =
    AppApiConfig.API_PARTNERS_URL + "/AddEditPartner";
  static API_GENERATE_PARTNER_ID_URL =
    AppApiConfig.API_PARTNERS_URL + "/GeneratePartnerId";
  static API_LOAD_PARTNER_DETAIL_URL =
    AppApiConfig.API_PARTNERS_URL + "/GetPartnerById";
  static API_CHANGE_PARTNER_MODULE_STATUS_URL =
    AppApiConfig.API_PARTNERS_URL + "/ActiveDeactivePartner";
  /* PARTNERS MODULE*/

  /* ACTIVITY MODULE*/
  static API_ACTIVITY_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Activity";
  static API_ACTIVITY_MODULE_LIST_URL =
    AppApiConfig.API_ACTIVITY_URL + "/GetAllActivityDetailList";
  static API_SAVE_ACTIVITY_DETAIL_URL =
    AppApiConfig.API_ACTIVITY_URL + "/AddEditActivity";
  static API_LOAD_ACTIVITY_DETAIL_URL =
    AppApiConfig.API_ACTIVITY_URL + "/GetActivityDetailById";
  /* ACTIVITY MODULE*/

  /* ACTIVITY FIELD MODULE*/
  static API_ACTIVITY_FIELD_URL =
    AppApiConfig.API_ADMIN_ENDPOINT + "/ActivityField";
  static API_ACTIVITY_FIELD_MODULE_SELECT_LIST_URL =
    AppApiConfig.API_ACTIVITY_FIELD_URL + "/GetAllActivityList";
  static API_SAVE_ACTIVITY_FIELD_DETAIL_URL =
    AppApiConfig.API_ACTIVITY_FIELD_URL + "/AddEditActivityFields";
  /* ACTIVITY MODULE*/

  /*FLEET MANAGEMENT MODULE */

  /*VEHICLE RELATED ENDPOINTS */
  static API_VEHICLE_MANAGEMENT_URL =
    AppApiConfig.API_ADMIN_ENDPOINT + "/Vehicle";
  static API_VEHICLE_SELECT_LIST_URL =
    AppApiConfig.API_VEHICLE_MANAGEMENT_URL + "/GetVehicleList";
  static API_FLEET_MANAGEMENT_LIST_URL =
    AppApiConfig.API_VEHICLE_MANAGEMENT_URL + "/GetVehicleDetailList";
  static API_VEHICLE_TYPE_SELECT_LIST_URL =
    AppApiConfig.API_VEHICLE_MANAGEMENT_URL + "/GetVehicleTypeList";
  static API_VEHICLE_CLASS_SELECT_LIST_URL =
    AppApiConfig.API_VEHICLE_MANAGEMENT_URL + "/GetVehicleClassList";
  static API_FUEL_TYPE_SELECT_LIST_URL =
    AppApiConfig.API_VEHICLE_MANAGEMENT_URL + "/GetFuelTypeList";
  static API_SAVE_VEHICLE_INFORMATION_MODULE_URL =
    AppApiConfig.API_VEHICLE_MANAGEMENT_URL + "/AddEditVehicle";
  static API_LOAD_VEHICLE_DETAIL_URL =
    AppApiConfig.API_VEHICLE_MANAGEMENT_URL + "/GetVehicleDetailById";
  /*VEHICLE RELATED ENDPOINTS */

  /*MAINTENANCE AND REPAIRS MODULE RELATED ENDPOINTS*/
  static API_MAINTENANCE_AND_REPAIRS_URL =
    AppApiConfig.API_ADMIN_ENDPOINT + "/Maintenance";
  static API_SAVE_MAINTENANCE_AND_REPAIRS_INFORMATION_MODULE_URL =
    AppApiConfig.API_MAINTENANCE_AND_REPAIRS_URL + "/AddEditMaintenance";
  static API_MAINTENANCE_AND_REPAIRS_LIST_URL =
    AppApiConfig.API_MAINTENANCE_AND_REPAIRS_URL + "/GetAllMaintenanceList";
  static API_LOAD_MAINTENANCE_AND_REPAIRS_INFORMATION_URL =
    AppApiConfig.API_MAINTENANCE_AND_REPAIRS_URL +
    "/GetMaintenanceDetailByDate";
  /*MAINTENANCE AND REPAIRS MODULE RELATED ENDPOINTS*/

  /*FUEL MANAGEMENT RELATED MODULE ENDPOINTS*/
  static API_FUEL_MANAGEMENT_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Fuel";
  static API_SAVE_FUEL_MANAGEMENT_INFORMATION_MODULE_URL =
    AppApiConfig.API_FUEL_MANAGEMENT_URL + "/AddEditFuel";
  static API_FUEL_MANAGEMENT_LIST_URL =
    AppApiConfig.API_FUEL_MANAGEMENT_URL + "/GetAllFuelList";
  static API_LOAD_FUEL_MANAGEMENT_INFORMATION_URL =
    AppApiConfig.API_FUEL_MANAGEMENT_URL + "/GetFuelDetailByDate";
  /*FUEL MANAGEMENT RELATED MODULE ENDPOINTS*/

  /*DRIVER INFORMATION RELATED MODULE ENDPOINTS*/
  static API_DRIVER_INFORMATION_URL =
    AppApiConfig.API_ADMIN_ENDPOINT + "/Driver";
  static API_SAVE_DRIVER_INFORMATION_MODULE_URL =
    AppApiConfig.API_DRIVER_INFORMATION_URL + "/AddEditDriver";
  static API_DRIVER_INFORMATION_LIST_URL =
    AppApiConfig.API_DRIVER_INFORMATION_URL + "/GetDriverListByVehicleId";
  static API_LOAD_DRIVER_INFORMATION_URL =
    AppApiConfig.API_DRIVER_INFORMATION_URL + "/GetDriverDetailById";
  /*DRIVER INFORMATION RELATED MODULE ENDPOINTS*/

  /*TRIP MANAGEMENT RELATED MODULE ENDPOINTS*/
  static API_TRIP_MANAGEMENT_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Trip";
  static API_SAVE_TRIP_MANAGEMENT_INFORMATION_MODULE_URL =
    AppApiConfig.API_TRIP_MANAGEMENT_URL + "/AddEditTrip";
  static API_TRIP_MANAGEMENT_LIST_URL =
    AppApiConfig.API_TRIP_MANAGEMENT_URL + "/GetAllTripListByVehicleId";
  static API_LOAD_TRIP_MANAGEMENT_INFORMATION_URL =
    AppApiConfig.API_TRIP_MANAGEMENT_URL + "/GetTripDetail";
  /*TRIP MANAGEMENT RELATED MODULE ENDPOINTS*/

  /*FLEET MANAGEMENT MODULE */

  /*FINANCE MODULE */
  static API_FINANCE_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Finance";
  static API_FINANCE_DASHBOARD_MODULE_LIST_URL =
    AppApiConfig.API_FINANCE_URL + "/GetAllFinanceDetailList";
  // static API_FINANCE_DASHBOARD_MODULE_LIST_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Common/GetStaticProjectList_Temp";
  static API_GENERATE_FINANCE_PDF_URL =
    AppApiConfig.API_FINANCE_URL + "/GenerateFinancePDF";
  /*FINANCE MODULE */

  /* DASHBOARD BASE URL */

  static API_DASHBOARD_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/Dashboard";

  /* HR DASHBOARD */
  static API_HR_DASHBOARD_MODULE_LIST_URL =
    AppApiConfig.API_DASHBOARD_URL + "/GetHRDashboardList";
  /* HR DASHBOARD */

  /* ADMIN DASHBOARD */
  static API_ADMIN_DASHBOARD_MODULE_DATA_URL =
    AppApiConfig.API_DASHBOARD_URL + "/GetAdminDashboard";
  /* ADMIN DASHBOARD */

  /* DASHBOARD BASE URL */

  /* JOB MODULE */
  static API_JOB_URL = AppApiConfig.API_ADMIN_ENDPOINT + "/job";
  static API_JOB_MODULE_LIST_URL =
    AppApiConfig.API_JOB_URL + "/GetAllJobDetailList";
  static API_GET_JOB_TYPE_SELECT_LIST_URL =
    AppApiConfig.API_JOB_URL + "/GetAllJobTypeList";
  static API_GENERATE_JOB_ID_URL =
    AppApiConfig.API_JOB_URL + "/GenerateProjectId";
  static API_GET_CUSTOMER_NAME_SELECT_LIST_URL =
    AppApiConfig.API_JOB_URL + "/GetCustomerNameList";
  static API_GET_PROJECT_BY_CUSTOMER_ID_SELECT_LIST_URL =
    AppApiConfig.API_JOB_URL + "/GetProjectByCustomerId";
  static API_GET_ALL_STATES_SELECT_LIST_URL =
    AppApiConfig.API_JOB_URL + "/GetAllStateList";
  static API_GET_CITY_BY_STATE_ID_SELECT_LIST_URL =
    AppApiConfig.API_JOB_URL + "/GetAllCityListByStateId";
  static API_ADD_EDIT_JOB_URL = AppApiConfig.API_JOB_URL + "/AddEditJob";
  static API_GET_JOB_BY_ID_URL = AppApiConfig.API_JOB_URL + "/GetJobDetailById";
  static API_GET_ALL_JOB_TIMELINE_LIST_URL =
    AppApiConfig.API_JOB_URL + "/GetAllJobTimelineList";
  static API_GET_ALL_JOB_MAP_LIST_URL =
    AppApiConfig.API_JOB_URL + "/GetAllJobMapList";
  static API_DELETE_JOB_MODULE_URL = AppApiConfig.API_JOB_URL + "/DeleteJob";
  /*JOB MODULE */

  static API_GOOGLE_MAPS_API_URL = "https://maps.googleapis.com/maps/api/js";
  static API_GOOGLE_MAPS_GEOCODE_URL =
    AppApiConfig.API_JOB_URL + "/GetGoogleMapsGeoCode";
  static API_GOOGLE_MAPS_PLACE_DETAILS_URL =
    AppApiConfig.API_JOB_URL + "/GetGoogleMapsPlaceId";
}
